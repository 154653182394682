<template>
  <q-page>
    <div class="page-header">
      <q-toolbar-title class="text-5 q-mr-auto q-ma-sm">Validar Conversões </q-toolbar-title>
      <q-breadcrumbs class="q-mr-auto q-ma-sm">
        <q-breadcrumbs-el label="Painel de Controle" to="/ " />
        <q-breadcrumbs-el label="Validar Conversões" exact />
      </q-breadcrumbs>
    </div>

    <header-validate-conversions
      @generateTable="generateTable"
      :propsClearFilter="clearFilter"
      @clearFilter="clearFilter = false"
    />
    <form-validate-conversions v-if="validadeFilter" :dataFormCSV="dataFormCSV" @clearFilter="clearFilter = true" />
  </q-page>
</template>

<script>
import HeaderValidateConversions from '../../components/campaign/HeaderValidateConversions.vue';
import FormValidateConversions from '../../components/campaign/FormValidateConversions.vue';

export default {
  components: { HeaderValidateConversions, FormValidateConversions },
  name: 'PageIncludeSites',
  data() {
    return {
      validadeFilter: false,
      dataFormCSV: null,
      dataTable: [],
      clearFilter: false
    };
  },
  methods: {
    generateTable(e) {
      this.validadeFilter = true;
      this.dataFormCSV = e;
    }
  }
};
</script>
