<template>
  <q-card flat bordered class="q-pa-md q-ma-md" v-show="show && dataFormCSV.length >= 1">
    <q-form @submit.prevent="validateConversions">
      <div class="row justify-center q-pt-md" style="background-color: #f3e5f5">
        <div class="q-mx-lg">
          <p>
            <b>Campanha:</b> <span>{{ dataFormCSV[2] ? dataFormCSV[2].campaignId.name : null }}</span>
          </p>
          <p>
            <b>ID da Campanha:</b> <span>{{ dataFormCSV[2] ? dataFormCSV[2].campaignId.id : null }}</span>
          </p>
        </div>
        <div class="q-mx-lg">
          <p>
            <b>Status das conversões: </b>
            <span>{{ dataFormCSV[2] ? dataFormCSV[2].status.label : null }}</span>
          </p>
          <p>
            <b>Mês de validação:</b> <span>{{ dataFormCSV[2] ? dataFormCSV[2].date : null }}</span>
          </p>
        </div>
        <!-- <q-separator vertical inset /> -->
        <div class="q-mx-lg">
          <p>
            <b>Quant. de Conversões:</b> <span>{{ dataFormCSV[3] ? dataFormCSV[3].quantidade : null }}</span>
          </p>
          <p>
            <b>PI Validado:</b>
            <span>R$ {{ dataFormCSV[3] ? parseFloat(dataFormCSV[3].pi_validado).toFixed(2) : null }}</span>
          </p>
        </div>
        <div class="q-mx-lg">
          <p>
            <b>Custo Validado:</b>
            <span>R$ {{ dataFormCSV[3] ? parseFloat(dataFormCSV[3].custo_validado).toFixed(2) : null }}</span>
          </p>
          <p>
            <b>Margem Validada:</b>
            <span>R$ {{ dataFormCSV[3] ? parseFloat(dataFormCSV[3].margem_validada).toFixed(2) : null }}</span>
          </p>
        </div>
      </div>
      <div class="row justify-center" style="background-color: #f3e5f5">
        <p>
          <q-btn
            v-if="dataFormCSV[3] && dataFormCSV[3].validados.length > 0"
            :label="
              dataFormCSV[3]
                ? `${dataFormCSV[3].validados.length}` +
                  ' conversões já com status ' +
                  `${dataFormCSV[2].status.label} `
                : null
            "
            no-caps
            unelevated
            color="purple"
            @click="
              exportTableCsv(
                dataFormCSV[3].validados.map((id) => ({ id })),
                columnsLocal,
                'Conversões Validadas'
              )
            "
          >
            <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
              {{ $t('export_csv') }}
            </q-tooltip>
          </q-btn>
        </p>
      </div>

      <div class="q-mt-md col-12 text-right">
        <q-btn color="red" label="Cancelar" no-caps outline class="q-px-md q-mx-md" @click="clearFilter" />
        <q-btn
          ref="btnSave"
          color="positive"
          label="Salvar"
          no-caps
          unelevated
          class="q-px-md"
          @click="validationClicked"
        />
      </div>
    </q-form>
  </q-card>
</template>

<script>
import UserMixin from '../../mixins/UserMixin';
export default {
  mixins: [UserMixin],
  data() {
    return {
      paginationCustom: { rowsPerPage: 99999999999 },
      columnsLocal: [
        {
          label: 'ID',
          field: 'id'
        }
      ],
      show: true,
      objData: {}
    };
  },

  props: {
    dataFormCSV: {
      type: [Array, Object],
      require: true
    }
  },

  created() {
    this.ActionSetFilterUser(`email=${this.user.email}`);
    this.getUsers();
  },

  mounted() {
    this.$refs.btnSave.$el.focus();
  },

  methods: {
    validationClicked() {
      this.showDialog(
        'Tem certeza que deseja atualizar o status da(s) conversão(ões)?',
        '',
        () => {
          this.validateConversions();
        },
        () => {
          this.onCancel;
        }
      );
    },

    onCancel() {
      this.successNotify('Ação cancelada');
    },

    async validateConversions() {
      let dateFormat = this.dataFormCSV[2].date.split('/');
      let jsonID = this.dataFormCSV[0];

      this.objData = {
        status: this.dataFormCSV[2].status.value,
        periodo: `${dateFormat[1]}-${parseInt(dateFormat[0])}-1`,
        user_id: this.users[0].id
      };

      console.log('Mais de 990 Ids? ', jsonID.length > 990);

      try {
        this.onLoading(true);

        this.objData.json = jsonID.map((item) => item).join(',');

        const { status } = await this.$http.put(`v2/redirect`, {
          app: 'conversorphp',
          path: `/api/validateConversion/${this.dataFormCSV[2].campaignId.id}`,
          params: {
            data: this.objData
          }
        });
        if (status === 200 || status === 201) {
          this.successNotify(
            'Conversões enviadas para processamento. Após finalizado, será encaminhado um e-mail para o usuário responsável.'
          );
        }
        this.$emit('clearFilter');
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    dateParse(date) {
      let dateArray = date.split('/');
      return `${dateArray[1]}-${dateArray[0]}-01`;
    },

    clearFilter() {
      this.$emit('clearFilter');
    },

    htmlDecode(html) {
      let txt = document.createElement('textarea');
      txt.innerHTML = html;
      return txt.value;
    },

    async requestValidation() {}
  }
};
</script>
